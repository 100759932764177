import React from 'react';
import './Contact.css';

const Contact = () => {
    return (
        <div className='container2 m-3'>
        <div className='row m-3 pad App1 container'>
            <img className='mw br' src='assets/ContactImg.jpg'></img>

            <div className=" col-md-6 contact-container">
                <b><h3 className="contact-title">Contact:</h3></b>
                <h4 className="contact-text">
                   Samreen Iqbal
                    </h4>
                <h5><b>Email: </b>samreen.iqbal1@outlook.com</h5>
            </div>


        </div>
        </div>
    );
};

export default Contact;