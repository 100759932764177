import React from 'react';

const Header = () => {
    return (
        <header className="bg text-light py-3 head">
            <div className="container">
                <div className="display-5 headwoa">Whispers of Art</div>
                <h5 className='light'>Illustration Portfolio by Samreen Iqbal</h5>
            </div>
        </header>
    );
};

export default Header;