import React from 'react';
import './AboutMe.css';

const AboutMe = () => {
    return (
        <div className='row pad App1'>

            
            <div className="about-container col-md-6">
                <h1 className="about-title">About Me</h1>
                <h4 className="about-text">
                    My name is <strong>Samreen Iqbal</strong>. I was born in Germany but my roots are Pakistani. I live in the UK, Bristol. While I hold a degree in Biomedical Science and currently work in a laboratory, testing blood for bacteria, my true passion lies in art. From childhood, drawing and painting have been my voice, a way to tell stories that words cannot. My illustrations demonstrate a wide range of art styles, reflecting how my scientific background has further fueled my creativity and adaptability, combining precision with imagination to create unique and captivating images.
                </h4>
            </div>
        
            <img className='mw br'  src='assets/AboutMe.png'></img>
           
           
        </div>
    );
};

export default AboutMe;
