import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer bg">
      <p>Contact: samreen.iqbal1@outlook.com</p>
      <p>&copy; 2024 Samreen Iqbal. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
