import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function Navbar() {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-black nav-p ">
            <a className="color-purple m1 m-2" href="/">Whispers Of Art</a>
            <div className="navbar-collapse bold" id="navbarNav">
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item active">
                        <a className="nav-link" href="/">Home</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/gallery">My Gallery</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/aboutme">About Me</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/contact">Contact</a>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default Navbar;