import React from 'react';
import Slider from 'react-slick';
import './HomePage.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function HomePage() {

    const carouselImages = [
        'assets/Carousel1.png',
        'assets/Carousel2.png',
        'assets/Carousel3.png',
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        fade: true,
    };

    return (
        <div className='home-container App'>
                                <div className="intro-card">
                        <h2 className="intro-text">
                            Whispers of Art, a world where art and storytelling come together. Each illustration whispers a piece of wisdom through a journey filled with meaningful lessons to inspire and teach, while sparking curiosity and wonder.
                        </h2>
                    </div>
            <div className="carousel-container">

                <Slider {...settings} className="carousel">
                    {carouselImages.map((image, index) => (
                        <div key={index}>
                            <img src={image} alt={`Carousel ${index + 1}`} className="carousel-image" />
                        </div>
                    ))}
                </Slider>


                <div className="content-overlay">

                    <button className="gallery-button">
                        <a className="gallery-link" href="/gallery">My Gallery</a>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default HomePage;
