import React from 'react';
import './App.css';
import Gallery from './Gallery';
import Footer from './Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import Navbar from './Navbar';
import Header from './Header';
import AboutMe from './AboutMe';
import Contact from './Contact';

function App() {
  return (
    <div className='App'>
      <Navbar />
      <Header />
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/aboutme" element={<AboutMe />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
      <Footer />
    </div>

  );
}

export default App;