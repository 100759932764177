import React, { useState } from 'react';
import './Gallery.css';

function Gallery() {
  const [selectedImage, setSelectedImage] = useState(null);

  const artworks = [
    { id: 1, title: 'The Mysterious Tiger', image: 'assets/1. Tiger.jpg', description: 'With its sharp gaze and serene demeanour, the image of a tiger symbolises mystery, strength, and grace.' },
    { id: 2, title: 'Sunset Cliff', image: 'assets/2.Sunset Cliff.jpg', description: "The image of the lone man sitting on a cliff's brink at dusk represents the tension between finality and the promise of what lies beyond, as well as the delicate balance between life's transient beauty and the unknown." },
    { id: 3, title: 'Santa Globe', image: 'assets/3. Santa Globe.jpg', description: "The wonder of Christmas, as Santa tenderly gives the young girl a gift that symbolises warmth and the joy of giving." },
    { id: 4, title: 'Christmas Joy', image: 'assets/4. Christmas joy.jpg', description: "A peaceful village in the winter, representing the wonder, cosiness, and hope of the season." },
    { id: 5, title: 'Snowmen', image: 'assets/5.Snowmen.jpg', description: "Showcases three happy snowmen, representing cosiness and warmth." },
    { id: 6, title: 'Classroom', image: 'assets/6. Classroom.jpg', description: "Children sit quietly in a lively classroom, symbolising curiosity, concentration, and the delight of group discovery. This picture perfectly encapsulates the essence of learning." },
    { id: 7, title: 'Boats', image: 'assets/7. Boat.jpg', description: "A curious duck approaches the two empty boats that are swinging gently at the dock, adding a delicate touch of life to the otherwise peaceful space." },
    { id: 8, title: 'Rabbits', image: 'assets/8. Rabbits.jpg', description: "As his family watches him go through a voyage of wonder and development, a young rabbit follows a butterfly, symbolising innocence and curiosity." },
    { id: 9, title: 'Suspension Bridge (Day)', image: 'assets/9. Suspension Bridge day.jpg', description: "The Clifton Suspension Bridge captures the classic Victorian engineering and the surrounding natural splendour as it effortlessly spans the Avon Gorge." },
    { id: 10, title: 'Suspension Bridge (Night)', image: 'assets/10. Suspension bridge night.jpg', description: "The calm reflection of the softly shimmering lights on the Clifton Suspension Bridge may be seen over the dark waters of the Avon Gorge." },
    { id: 11, title: 'Scared Child', image: 'assets/11. Scared child.jpg', description: 'The girl\'s inner anguish and terror are depicted in this painting as ghosts lurking in her thoughts, oblivious of the hidden world of beauty.' },
    { id: 12, title: 'Bird in Cage', image: 'assets/12. Bird in cage.jpg', description: 'This picture stands for liberation from restrictions and limitations as well as the pursuit of one\'s actual potential.' },
    { id: 13, title: 'Flower', image: 'assets/13. Flower.jpg', description: 'Each petal of the flower opens like a soft promise of fresh starts, serving as a metaphor of growth.' },
    { id: 14, title: 'Mickey and Minnie', image: 'assets/14. Mickey and Minnie.jpg', description: 'Mickey and Minnie shown in perfect harmony and love, which is the beacon of joy that lights up the sky.' },
    { id: 15, title: 'Henna Hands', image: 'assets/15. Henna hands.jpg', description: 'Henna cascades down the hands like delicate vines, telling tales of beauty and heritage.' },
    { id: 16, title: 'Friendship vs Isolation', image: 'assets/16. Friendship vs isolation.jpg', description: 'Both sides of the image experience the same sunset, but through different lenses—one through the comfort of connection, and the other through the stillness of self-discovery.' },
    { id: 17, title: 'Henna Feet', image: 'assets/17. Henna Feet.jpg', description: 'Each stride is guided with beauty and tradition by the detailed maps-like henna patterns on both feet.' },
    { id: 18, title: 'Boy Fishing', image: 'assets/18. Boy fishing.jpg', description: 'A little boy fishing is like hope casting its line into the waters of possibility, waiting for life’s simple rewards.' },
    { id: 19, title: 'Boy Making a Wish', image: 'assets/19. Boy making a wish.jpg', description: 'A boy\'s hopes soar to the sky as his prayer follows a shooting star.' },
    { id: 20, title: 'Privileged Girl', image: 'assets/20. Privileged girl.jpg', description: 'Two different worlds share the same park: one wrapped in gifts, the other in survival.' },
    { id: 21, title: 'Alice in Wonderland', image: 'assets/21. Alice in Wonderland.jpg', description: 'Alice caught between chaos, curiosity, and fate\'s watchful gaze as time races and pathways bend in Wonderland\'s whimsical atmosphere.' },
    { id: 22, title: 'Girl with Plants', image: 'assets/22. Girl with plants.jpg', description: 'A young girl resembling a budding seedling, full of promise and eager to develop.' },
    { id: 23, title: 'Apple', image: 'assets/23. Apple.jpg', description: 'Ripe with the promise of sweetness and life, the apple glistens like a jewel of nature.' },
    { id: 24, title: 'Ambitious Boy', image: 'assets/24. Ambitious boy.jpg', description: 'An ambitious boy ascending the lunar ladder in hope of fulfilling his limitless goals.' },
    { id: 25, title: 'Girl Reading in Jungle', image: 'assets/25. Girl reading in Jungle.jpg', description: 'Her words become a bridge in the middle of the wild, transforming curious creatures into enthralled listeners of untamed tales.' },
    { id: 26, title: 'Girl in Sunflower Farm', image: 'assets/26. Girl in sunflower farm.jpg', description: 'The little girl blowing on a dandelion in a sunflower field is like a dreamer releasing her wishes into a world already blooming with possibilities.' },
    { id: 27, title: 'City Life', image: 'assets/27. City life.jpg', description: 'A city beating with the pulse of ambition.' },
    { id: 28, title: 'Ant in Danger', image: 'assets/28. Ant in danger.jpg', description: 'The tiniest of creatures stands firm against the weight of the planet overhead, transforming fragility into an unshakable force of protection.' },
    { id: 29, title: 'Mother and Daughter (Paris)', image: 'assets/29. Mother and daughter in paris.jpg', description: 'Her hand serves as a compass and anchor for her child through the glories of a boundless world under the shadow of the Eiffel Tower.' },
    { id: 30, title: 'Little Girl Feeding Deer', image: 'assets/30. Little girl feeding deer.jpg', description: 'Even the snow remains motionless to observe her kind hands bridging the realms of warmth and wonder in the silent embrace of winter.' },
    { id: 31, title: 'Mother Duck Shielding', image: 'assets/31. Mother duck shielding.jpg', description: 'Protecting her delicate world from harm with her broad wings, the mother duck transforms into a stronghold of love.' },
    { id: 32, title: 'Smiling Woman in Pain', image: 'assets/32. Smiling Woman in pain.jpg', description: 'Her smile serves as a facade of freedom, while her eyes reveal the chains of her soul.' },
    { id: 33, title: 'Boy Hunting Treasure', image: 'assets/33. Boy hunting treasure.jpg', description: 'He goes far in pursuit of riches, never appreciating the blessings he already holds.' },
    { id: 34, title: 'Mother Hugging Baby', image: 'assets/34. Mother hugging baby.jpg', description: 'The world dissolves in her embrace, leaving just the purest love nestled in her arms.' },
    { id: 35, title: 'Mother Playing with Child', image: 'assets/35. Mother playing with child.jpg', description: 'Their laughter fills the air, as they discover the world together through play.' },
    { id: 36, title: 'Mother Cat with Kitten', image: 'assets/36. Mother cat with kitten.jpg', description: 'A tender bond woven in soft fur and gentle purrs.' },
    { id: 37, title: 'The Balance of Life', image: 'assets/37. Balance of life.jpg', description: 'Opposites rest in perfect harmony, as the scales balance the dualities of life.' },
    { id: 38, title: 'A Proposal in Switzerland', image: 'assets/38. Man proposing Switzerland.jpg', description: 'He kneels on the Lake Brienz pier, extending a love that is as ageless as the surrounding mountains.' },
    { id: 39, title: 'The Guiding Light', image: 'assets/39. Glowing roses on grave.jpg', description: 'Even in the shadow of loss, the blooming roses whisper that light and beauty can still grow from the depths of sadness.' },
    { id: 40, title: 'River', image: 'assets/40. River .jpg', description: 'The boy holding onto the log represents resilience in life\'s challenges, staying grounded in strength and hope, while his friends are swept away by the current of negativity.' },
    { id: 41, title: 'Asking for Peace', image: 'assets/41. Boy asking for peace.jpg', description: 'A boy asking for peace, like a traveler looking for the end of the world, not realising that the calmness he desires is already inside his own heart.' },
    { id: 42, title: 'Feather Transformation', image: 'assets/42. Feather transformation.jpg', description: 'As you note down your dreams, they take flight, transforming into birds that soar toward reality.' },
    { id: 43, title: 'Diversity', image: 'assets/43. Diverse kids playing.jpg', description: 'Children from different walks of life come together in joyous play, showcasing the beauty of diversity and unity in their shared laughter and moments.' },
    { id: 44, title: 'Bridge of Friendship', image: 'assets/Bridge.jpg', description: 'Represents connection, cooperation, and how true friendship helps overcome challenges.' },
    { id: 45, title: 'Connection', image: 'assets/Connection.jpg', description: 'A reflection of how our thoughts shape our own well-being.' },
    { id: 46, title: 'Fireflies', image: 'assets/Firefly.jpg', description: 'As she watches the firefly glow inside the jar, she realises that even in captivity, light manages to shine.' },
    { id: 47, title: 'The Halloween Castle', image: 'assets/Halloween_Castle.jpg', description: ' Despite life’s mysteries and terror, there is always a light at the end of the tunnel that is willing to guide us.' },
    { id: 48, title: 'Lanterns', image: 'assets/Lanterns.jpg', description: 'A family releasing illuminated lanterns, a symbol of hope and togetherness.' },
    { id: 49, title: 'The Mosque', image: 'assets/Mosque.jpg', description: 'An illuminated mosque underneath the moonlight, guiding hearts through the night.' },
    { id: 50, title: 'Tree of Life', image: 'assets/Tree_Of_Life.jpg', description: 'The journey of a tree from seed to maturity mirrors our growth as individuals, facing life with resilience and strength.' },
    { id: 51, title: 'The Old Cottage', image: 'assets/The_Old_Cottage.jpg', description: 'Like a lost bird returning to it’s nest, the man returns to the warmth of his roots.' },
    { id: 52, title: 'Flying Kites', image: 'assets/Flying_Kites.jpg', description: 'This image represents freedom and joy, the children’s dreams soar as high as the kites in the sky.' },
    { id: 53, title: 'A New Perspective', image: 'assets/New_Perspective.jpg', description: 'Represents seeing the world in a new light or perspective.' },
    { id: 54,  title: 'The Snail', image: 'assets/Snail.jpg', description: 'A small snail resting on a leaf, demonstrates patience, slowness and connection to nature, a reminder to humans of the need for moments of stillness and reflection especially after facing struggles.' },
    { id: 55, title: 'The Lighthouse', image: 'assets/Lighthouse.jpg', description: 'This image represents the perserverance of a  human spirit, standing like a lighthouse through the roughest storms, guiding us through the turbulence of life.' },
    { id: 56, title: 'Constellations', image: 'assets/Constellation.jpg', description: 'An image of a man gazing at Ursa Major and Ursa Minor star constellation, representing a soul seeking guidance and direction amidst the vast unknown.' },
    { id: 57, title: 'The Mother Bird', image: 'assets/Mother_Bird_Feeding.jpg', description: 'A mother bird tending to her young symbolizes the unwavering love and support that sustains growth and survival in a challenging world.' },
    { id: 58, title: 'Praying Salah', image: 'assets/Praying_Salah.jpg', description: 'This image displays how the children dedicate themselves to God in prayer with full faith and pure devotion, creating a profound connection that transcends the physical world, fostering a sense of peace and purpose in their lives.' },
    { id: 59, title: 'Timeless Love', image: 'assets/Timeless_Love.jpg', description: 'This image represents the steadfast love of an elderly couple that has become sweeter over time, with each sprinkling of a heart serving as a reminder of their lasting connection.' },
    { id: 60,  title: 'Bike riding', image: 'assets/Riding_Bike.jpg', description: 'A father teaching his child to ride a bike, their journey a symbol of how support transforms uncertainty into the happiness of independence.' },
    { id: 61, title: 'Traditional Framing 1', image: 'assets/Traditional_Frame_1.jpg', description: 'Life is like a mirror, smile at it and it smiles back at you!' },
    { id: 62, title: 'Traditional Framing 2', image: 'assets/Traditional_Frame_2.jpg', description: 'We are all visitors on Earth, all with the same purpose. To learn, to grow, and to love, until we return home.' },
    { id: 63, title: 'Beehive', image: 'assets/Beehive.jpg', description: 'A representation of how hard work and coordination can help us achieve our dreams while nurturing connections with every drop of shared joy further sweetening our lives.' },
    { id: 64, title: 'The Dogs Reflection', image: 'assets/Dog_Reflection_.jpg', description: 'Blinded by greed, the dog gazes into the river mistaking his own reflection for another dog, eager to claim the second bone he believes to be his rivals riches despite holding riches himself.' },
    { id: 65, title: 'The Ladybug', image: 'assets/Ladybug.jpg', description: 'Ladybugs can be seen as messengers of love and renewal, they encourage us to embrace the present and welcome the beauty and blessings that arise through patience and the willingness to change.' },
    { id: 66, title: 'The Library', image: 'assets/Library.jpg', description: 'This image illustrates our ability to immerse ourselves in the worlds we choose, freely attaining wisdom as we navigate through the pages.' },
    { id: 67, title: 'The Peacock', image: 'assets/Peacock.jpg', description: 'This image highlights the interconnectedness of a womans experiences and growth throughout her life and how she embraces the wisdom and dignity that come with being a woman while retaining the innocence and delight of her childhood.' },
    { id: 68, title: 'Ramadan', image: 'assets/Ramadan.jpg', description: 'A sacred month of fasting, prayer and reflection observed by Muslims. This image represents the joy of a family coming together to break a fast during sunset (iftar), while praying to God and expressing gratitude for his countless blessings.' },
    { id: 69, title: 'The Tree Swing', image: 'assets/Swing_On_Tree.jpg', description: 'A nostalgic glimpse into childhood swings through the bright shining petals in the night.' },
    { id: 70, title: 'The Boy Who Cried Wolf', image: 'assets/The_Boy_Who_Cried_Wolf.jpg', description: 'The image represents how the shepherd repeatedly lies about the wolf eating his flock of sheep fooling the villagers, until one day when it happens for real, nobody arrives to help which is a consequence of his dishonesty.' },
    { id: 71, title: 'The Path of Choices', image: 'assets/The_Path_Of_Choices.jpg', description: 'At the crossroads of life, we are faced with many choices where the comfort of familiar paths or the comfort zone may lead to danger, while the harder roads, filled with challenges and obstacles ultimately lead to true happiness.' },
    { id: 72, title: 'The Umbrella', image: 'assets/Umbrella.jpg', description: 'A representation of kindness that can turn rain into a sanctuary and serve as protection in tough times.' },
    { id: 73, title: 'The Genie Lamp', image: 'assets/Genie_Lamp.jpg', description: 'This image illustrates an act of selflessness. Rather than using the genie lamp himself, the boy gives it to his friend, and this selfless act fulfills both their wishes.' },
    { id: 74, title: 'Eid Shopping', image: 'assets/Eid_Shopping.jpg', description: 'The image symbolizes warmth of family bonds and the joy of cultural traditions shared across generations.' },
    { id: 75, title: 'Oasis', image: 'assets/Oasis.jpg', description: 'As the little boy offers his bowl of water to the thirsty camel in the scorching heat, the desert magically transforms into a beautiful oasis.' },
    { id: 76, title: 'The Sandcastle', image: 'assets/Sandcastle.jpg', description: 'Like the sandcastle some joys in life are often temporary, however it is the teamwork, the creativity and togetherness where the true value lies, which should be savoured every minute.' },
    { id: 77, title: 'The Injured Bird', image: 'assets/The_Injured_Bird.jpg', description: 'Illustrates how through compassion and empathy even small acts of kindness can provide comfort and healing. We all have the capability of supporting one another, no matter our own limitations.' },
    { id: 78, title: 'White Dove', image: 'assets/White_Dove.jpg', description: 'A little girl, who faithfully feeds a white dove each day, silently wishes for her prayers to be heard. Then, one day, as she waits to feed the bird once more, the white dove arrives carrying an envelope: the answer to her prayers.' },
    { id: 79, title: 'Nurturing the Plant', image: 'assets/Watering_Plant.jpg', description: 'Represents personal growth, showing that every quality in oneself can flourish with care and nurturing.' },
    { id: 80, title: 'Split Personality', image: 'assets/SplitPersonality.jpg', description: 'A delicate equilibrium of our inner feelings as it is torn between joy and rage.' },

  ];
  const openModal = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className=''>
      <section className="gallery">
        <div className="row">
          {artworks.map((art) => (
            <div key={art.id} className="col-md-4">
              <div className="card m-2">
                <img
                  src={art.image}
                  className="card-img-top image-container "
                  alt={art.title}
                  onClick={() => openModal(art.image)} // Move onClick here
                />
                <div className="card-body">
                  <h5 className="card-title">{art.title}</h5>
                  <p className="card-text">{art.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      {selectedImage && (
        <div className="modal" onClick={closeModal} style={{ display: selectedImage ? 'flex' : 'none' }}>
          <span className="close" onClick={closeModal}>&times;</span>
          <img className="modal-content" src={selectedImage} alt="Zoomed artwork" />
        </div>
      )}
    </div>
  );
}

export default Gallery;